import React from 'react';


function About() {
    return(
        <div>
            <section className="et-slide">
                <h1>About</h1>
            </section>
            <div className="aboutdescription">
                #ac_ac<br/>
                《ANIME×CLUB MUSIC》<br/>
                レイヴからインターネットまで取り揃えたサブカル系パーティー<br/>
                ~from Rave to Internet~<br/>
                Anime, Anison-remix and Sub-culture party<br/>
                "Animetic Anthemic"
            </div>
        </div>
    )
}

export default About;
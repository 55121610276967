import React from 'react';

const TrackListData = [
                ["01"," Alicemetix","Youth Continue","https://twitter.com/Alicemetix"],
                ["02"," kouhei","Secret Door", "https://twitter.com/kouhei333"], 
                ["03"," the dig account","#RRRRR","https://twitter.com/K55tea"], 
                ["04", " E___j_","May peace prevail in the world","https://twitter.com/Ennojo_"], 
                ["05"," kotatsu","ぴーてぃくぱーてぃく", "https://twitter.com/kot2_"], 
                ["06"," πL","Cherry blossom part 1", "https://twitter.com/paieru"], 
                ["07"," リスト異常","快眠！催眠！異常リスト生活", "https://twitter.com/list_izyo"], 
                ["08"," DJ出町やなぎ","運命を照らして", "https://twitter.com/zygani_emgc"], 
                ["09"," Kur@ra","Unexpected Us", "https://twitter.com/kurara_msks"], 
                ["10"," JJJ","Not Art Club", "https://twitter.com/JJJ_house3301"], 
                ["11"," TamolarM","Achiga Spirts", "https://twitter.com/karyllarm"], 
                ["12"," mocya","Precious Dream"], 
                ["13"," Mtell","Breaking the sound barrier", "https://twitter.com/Mtteeellll"], 
                ["14"," Polcka","Magic here tonight", "https://twitter.com/p0lcka"] 
                ];

export default TrackListData;
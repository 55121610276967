import React from 'react';

const EventListData = [
    [
        "Animetic Anthemic vol.8",
        "2019",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac8.jpg?alt=media&token=273e417a-3610-4185-a6d8-9f8a07fae0e1",
        "https://club-mogra.jp/2019/03/06/3763/"
    ],
    [
        "Animetic Anthemic vol.9",
        "2019",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac9.jpg?alt=media&token=3d9b1939-1b3f-4da0-8221-04e8677c0110",
        "https://club-mogra.jp/2019/05/08/3824/"
    ],
    [
        "Animetic Anthemic vol.10",
        "2019",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac10.jpg?alt=media&token=2a913d89-8098-4cf1-b7dd-c358dde13cee",
        "https://club-mogra.jp/2019/07/03/3916/"
    ],
    [
        "Animetic Anthemic vol.11",
        "2019",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac11.jpg?alt=media&token=334168c0-f254-4bfa-8080-0dcc0bbe99f6",
        "https://club-mogra.jp/2019/09/04/3952/"
    ],
    [
        "Animetic Anthemic vol.12",
        "2019",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac12.jpg?alt=media&token=960889aa-81e2-401c-8563-0747beaf5cd7",
        "https://club-mogra.jp/2019/11/06/4053/"
    ],
    [
        "Animetic Anthemic vol.13",
        "2020",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac13.jpg?alt=media&token=bb656b32-b205-4621-932c-59734787b662",
        "https://club-mogra.jp/2020/01/08/4097/"
    ],
    [
        "Animetic Anthemic vol.14",
        "2020",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac14.jpg?alt=media&token=69609e3d-4f31-4bd1-9e2e-2ccf9beafde2",
        "https://club-mogra.jp/2020/03/08/4146/"
    ],
    [
        "Animetic Anthemic vol.15",
        "2020",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acac15.jpg?alt=media&token=61f17d1e-6512-4092-8951-444e18b66570",
        "https://club-mogra.jp/2020/09/20/4213/"
    ],
    [
        "NOT Animetic Anthemic",
        "2020",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/notacac.jpg?alt=media&token=8bd725bf-d61d-415e-aa08-ae94410a73ef",
        "https://club-mogra.jp/2020/02/06/4110/"
    ],
    [
        "#wtvsac",
        "2019",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/wtvsac.jpg?alt=media&token=f6f00439-b641-499f-a318-ed0ac7703ca8",
        "http://twvt.me/wtvsac"
    ],
    [
        "#amvsac -Prologue-",
        "2020",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/amvsacprologue.jpg?alt=media&token=ee4142e2-4061-4111-b91a-30ae32682a57",
        "https://club-mogra.jp/2020/07/19/4194/"
    ],
    [
        "#amvsac",
        "2020",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/amvsac.jpg?alt=media&token=47044ab8-35a3-4da3-baec-de5dc835b524",
        "https://club-mogra.jp/2020/11/22/4234/"
    ]
];

export default EventListData;
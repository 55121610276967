import React from 'react';

const AlbumListData = [
    [
        "AniCollective vol.2",
        "2018",
        "https://soundcloud.com/animetic_anthemic/sets/anicollective-vol-2",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/acaclogo.png?alt=media&token=89717ec8-411d-4f14-9c35-f9801febc835",
        "https://drive.google.com/file/d/1V93y2KdZCc10zZGUnKnSFn5C2jMLZIp8/view?usp=sharing"
    ],
    [
        "AniCollective vol.3",
        "2018",
        "https://soundcloud.com/animetic_anthemic/sets/anicollective-vol3",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.3.jpg?alt=media&token=05d696a2-229c-42f8-9087-ffc82918bb5d",
        "https://drive.google.com/file/d/1vnr3Ga8M6-9MjbxV3RKSLOxdPzOWXLd5/view?usp=sharing"
    ],
    [
        "AniCollective vol.4",
        "2018",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol4-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.4.jpg?alt=media&token=79455b6f-899f-45eb-bff8-254cc0263ee5",
        "https://drive.google.com/file/d/11N7GfvfDtKKJFV24-O0ICw2Z7F3REIDJ/view?usp=sharing"
    ],
    [
        "AniCollective vol.5",
        "2018",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol5-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.5.jpg?alt=media&token=6f8c7863-4552-49d7-87c9-c33fba90a45f",
        "https://drive.google.com/file/d/1f4Tq9hWyr7GRpKBHSbqgD7jj3v4lvRYs/view?usp=sharing"
    ],
    [
        "AniCollective vol.6",
        "2018",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol6-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.6.jpg?alt=media&token=eb2129d9-afdd-4903-8862-53017dcccc49",
        "https://drive.google.com/file/d/14OtBvjzbuNRyx68lTZ6F4mx6cH57H91L/view?usp=sharing"
    ],
    [
        "AniCollective vol.7",
        "2019",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol7-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.7.jpg?alt=media&token=e661dc16-3ddf-4acc-92a7-458bc8d6bb62",
        "https://drive.google.com/file/d/1Z3qxsgZdwPLbiAF7HyLYwo0PWKRax9uR/view?usp=sharing"
    ],
    [
        "AniCollective vol.8",
        "2019",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol8-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.8.jpg?alt=media&token=9aff20b7-f81b-41fd-9520-294588da1082",
        "https://drive.google.com/file/d/1ux4jSNiJHnA_bplDmq1wYErAP0p6a-Sc/view?usp=sharing"
    ],
    [
        "AniCollective vol.9",
        "2019",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol9-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.9.jpg?alt=media&token=606ea6fd-415e-47d1-8468-59c65822ad49",
        "https://drive.google.com/file/d/1O61ipuLpLXdcLT6QCvjHM2s4utl9UGlp/view?usp=sharing"
    ],
    [
        "AniCollective vol.10",
        "2019",
        "https://soundcloud.com/animetic_anthemic/anicollective-vol10-xfd",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/vol.10.jpg?alt=media&token=a54148ab-c077-4eef-abbb-4ecff2269996",
        "https://drive.google.com/file/d/15z5E3gzk4d58gQTp39z_at7mJd50lpGl/view?usp=sharing"
    ],
    [
        "AniCollective Neo",
        "2021",
        "",
        "https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/neo.jpg?alt=media&token=2129d89a-9a41-4e2a-99ea-78ef59f57b69",
        "https://drive.google.com/file/d/1Jh-uk6kleu-eF_XPFzmIDIdBcWJazVdI/view"
    ]
];

export default AlbumListData;
import React from 'react';

function Header() {
  return (
      <div className="header">
        <img className="headerimg" src="https://firebasestorage.googleapis.com/v0/b/acacofficial-5ed15.appspot.com/o/header.jpg?alt=media&token=eaf7d833-487a-464e-81de-855567487ba9" alt="header-img"></img>
      </div>
  );
}

export default Header;